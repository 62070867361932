import React from 'react'


export default class Video extends React.Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return (

            <section className="erp_action_area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">

                        <div className="video_info video_training" style={{background: `url(${this.props.videoImage}) no-repeat`, backgroundSize: 'cover'}}>
                            <div className="ovarlay_color"></div>
                            <div className="popup-youtube video_icon" onClick={() => this.props.setToggler(!this.props.toggler)} href={this.props.videoSrc} style={{display: 'block!important'}}>
                                <img src="/app-icons/layout-icons/youtube-round-color.svg" alt="Youtube Icon"/>
                                </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="erp_content">
                            <h2>Find out how our <strong>{this.props.videoStatement}</strong> can help your organization</h2>
                        </div>
                    </div>
                </div>
            </div>
            </section>

        )
    }
}