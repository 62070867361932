import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow';
import FeatureCards from "../../../components/features/FeatureCards"
import Video from '../../../components/features/Video'

export const query = graphql`
  query CompetenciesQuery {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
    const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=tCU89zYKCyU&feature=youtu.be"],
    }

    const bannerProps = {
        title: 'Competency Assessments',
        subtitle: `Incompetence in your workforce can be costly. Mistakes by unskilled workers can lead to accidents, injuries, property damage, spills, defects, delays, inefficiencies, customer and revenue losses, regulatory trouble, and even lawsuits. What is at the other end of that spectrum where safety, quality and efficiency rule? Competence. And how to you ensure Competency? Assessments! `,
        featureName: 'Competency',
        rightButtonText: 'Why Compentency Assessment Software?',
        rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
        mockupImg: '/features/competencies/header.png'
    }

    const questionProps = {
        // question: 'Has your bulletin board become a piece of antique abstract art?',
        answer: `The problem with incompetence is its inability to recognize itself. If you have the stomach, do a quick search for videos of people trying hover boards or unicycles for the first time. They usually start with some poor bloke thinking the new skill is simple enough and can be mastered in short order. This is usually followed by an epic fail. Your workplace is no different. Your new hand will no doubt watch an old pro effortlessly perform a task, misjudge the complexity of the task, and fail to grasp the subtle nuances exhibited by the veteran.`,
        questionTwo: `No doubt your organization has a system of training and overseeing new hires before they are exposed to operational risks, but this process likely has:`,
        answerBulletList: [
            'Loose or poorly defined standards',
            'Inconsistent conveyance of expectations and guidance',
            'An unclear path of progression',
            'Non-existent feedback and documentation',
        ],
        imageSrc: '/features/competencies/cartoon.jpg',
        imgAlt: 'Cartoon of man lacking the compentency to tye down a sail.'
    }

    const videoProps = {
        videoSrc: 'https://youtube.com/VOSUNYrSGvo',
        videoImage: '/features/competencies/youtubethumb.png',
        videoStatement: (<strong>Competency Assessments</strong>),
    }

    const seo = {
        title: 'Workhub | Free Competency Assessment Tool Evaluate',
        description: 'Our Competency Assessment Software lets you assess & review your employees performance, safety, proficiencies, behaviors & abilities so that you can reduce workplace accidents, boost productivity and improve efficiency.',
        image: '/features/competencies/social-card.jpg',
        // pathname,
        // article
    }

    const featureIndex = [
        {
            title: "Assess",
            para: "Ensure that your workers are safely and successfully performing procedures and daily tasks.",
        },
        {
            title: "Prerequisites",
            para: "Link policies, online training & certifications that are necessary to complete prior to a Competency Assessment.",
        },
        {
            title: "Checklist",
            para: "Build a checklist of behaviors, concepts & tasks that must be performed and observed during an operation, for a worker to be considered competent.",
        },
        {
            title: "Qualified Assessors",
            para: "Create a list of people that are qualified to test other members of your workforce.",
        },
        {
            title: "Commenting",
            para: "Assessors can add comments to detail worker performance or show where improvements need to be made. ",
        },
        {
            title: "History and Compliance",
            para: "Track and report on worker competency and follow up with those missing an assessment. ",
        },
        {
            title: "Performance Criteria",
            para: "Designate workers as Requires Supervision, Independently Competent or Instructor/Assessor.",
        },

    ]


    return (
        <Layout {...lightboxProps}>
            <SEO {...seo} {...data.site} />
            <Banner {...bannerProps} {...lightboxProps} />

            <section style={{ marginTop: '60px' }}>
                <div className="behaviour-container">
                    <div className="behaviour-grid">

                        {/* First Paragraph */}

                        <p style={{ padding: '1rem' }}>The problem with incompetence is its inability to recognize itself. If you have the stomach, do a quick search for videos of people trying hover boards or unicycles for the first time. They usually start with some poor bloke thinking the new skill is simple enough and can be mastered in short order. This is usually followed by an epic fail. Your workplace is no different. Your new hand will no doubt watch an old pro effortlessly perform a task, misjudge the complexity of the task, and fail to grasp the subtle nuances exhibited by the veteran.</p>

                        {/* Checklist */}

                        <div className="grid-item media-margin">
                            <p style={{ marginBottom: '2.5rem' }}>No doubt your organization has a system of training and overseeing new hires before they are exposed to operational risks, but this process likely has:</p>
                            <ul className="checklist">
                                <li>Loose or poorly defined standards</li>
                                <li>Inconsistent conveyance of expectations and guidance</li>
                                <li>An unclear path of progression</li>
                                <li>Non-existent feedback and documentation</li>
                            </ul>
                        </div>

                        {/* Cartoon */}

                        <div className="grid-item grid-item-flex">
                            <aside>
                                <ReactWOW animation="fadeInLeft" delay="0.3s">
                                    <img src={questionProps.imageSrc} alt="Competencies Cartoon" /></ReactWOW>
                            </aside>
                        </div>

                    </div>
                </div>
            </section>

            {/* Video Section */}

{/* 
            <section className="erp_action_area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">

                            <div className="video_info video_training" style={{ background: `url('${videoProps.videoImage}') no-repeat`, backgroundSize: 'cover' }}>
                                <div className="ovarlay_color"></div>
                                <div className="popup-youtube video_icon" onClick={() => this.props.setToggler(videoProps.toggler)} href={videoProps.videoSrc} style={{ display: 'block!important' }}>
                                    <img src="/app-icons/layout-icons/youtube-round-color.svg" alt="Youtube Icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="erp_content">
                                <h2>What are <strong>Competencies</strong> and why do <strong>Assessments</strong>?</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

<Video {...videoProps} {...lightboxProps} />

            {/* Info Section */}


            <section style={{ marginTop: '100px' }}>
                <div className="behaviour-container" style={{ borderBottom: '1px solid #ececf6' }}>
                    <div className="behaviour-grid" style={{ marginBottom: '20px' }}>



                        <h2 style={{ marginBottom: '1.5rem', marginLeft: '1.5rem' }}>Set Prerequisites and Performance Criteria</h2>



                        <div className="grid-item ">
                            <p>
                                Invariably, some tasks or procedures performed at your workplace are difficult, unsafe or otherwise prone to screwups. Ideally you'll have already loaded up Workhub's Procedures module with the steps involved in executing those procedures safety, identified the potential hazards that might throw things out of whack, and listed controls to mitigate or eliminate those hazards. You'll want to make those procedures, plus any related policies, online training and certification prerequisites for your "Competency".</p>

                            <p>
                                Then you'll want to identify actions, behaviors, or even awareness of certain concepts that are relevant to the successful execution of tasks that the operator should be able to demonstrate. That sentence is a bit of a mouthful, so at Workhub we just call these things "Performance Criteria". Ideally, each Criterion will be verifiable using one of the five senses. A verifiable criterion would be "Can safely back 53' trailer into loading dock". An unverifiable criterion would be "Can make customers happy".</p>
                        </div>



                        <div className="grid-item grid-item-flex">
                            <aside>
                                <ReactWOW animation="fadeInRight" delay="0.3s">
                                    <img src='/features/competencies/prereqs.jpg' style={{ marginBottom: '1.5rem' }} alt="Prerequisite Screenshot" /></ReactWOW>
                            </aside>
                        </div>

                    </div>
                </div>
            </section>


            <section style={{ borderBottom: '1px solid #ececf6', maxWidth: '1200px', margin: 'auto', padding: '2.5rem 1.5rem' }}>
                <h2 style={{ marginBottom: '1.5rem' }}>Build up a list of Qualified Assessors</h2>
                <p>Every Assessee first needs an Assessor, just like every egg first needs a chicken. It should be fairly easy to identify your qualified Assessors, as they're usually the ones that have been performing said tasks successfully up until now. Administrators in Workhub can prequalify these assessors, but a reason should be recorded, such as "15 years experience" or "Journeyman ticket".</p></section>


            <section style={{ borderBottom: '1px solid #ececf6', maxWidth: '1200px', margin: 'auto', padding: '2.5rem 1.5rem' }}>
                <h2 style={{ marginBottom: '1.5rem' }}>Assessees Request Assessments</h2>
                <p>We're not entirely sure that Assessee is a real word, but it's the term we've given to the newbies that need to prove their competency to the aforementioned masters in your organization. They can see the performance criteria that have been set for their competencies, and so know what to study, observe and practice (under supervision) in preparation for their assessment. When they're ready, they can choose from a list of available assessors, and request their assessment. Workhub sends the assessor a notification of the request, and ideally a suitable time and place (if applicable) are arranged. Administrators in Workhub can see a list of all pending assessment requests, in case some of them get too long in the tooth.</p></section>





            <section style={{ marginBottom: '80px' }}>
                <div className="behaviour-container">
                    <h2 style={{ marginBottom: '1.5rem', marginTop: '2.5rem', marginLeft: '1rem' }}>The Competency Assessment</h2>

                    <div className="behaviour-grid">

                        <div className="grid-item">
                            <p>
                                When the Assessor and Assessee get together for the penultimate competency assessment, it's usually a good idea for the Assessor to have a mobile phone or tablet with the Workhub assessment page for that individual open. [The Assessor can optionally print an assessment form and record the results later.] The process simply involves the Assessee performing the task, and the Assessor observing to see if all criteria are being met. If the individual being assessed is unable to even one criterion, the Assessee continues to be marked as "Requires Supervision" for that task. If all criteria are met, then the Assessee is marked as "Independently Competent" and their compliance score improves accordingly.</p>

                            <p>
                                One last thing... There is another competence level to which a Assessee can attain through an assessment, and that is "Instructor / Assessor", which allows Daniel-San to someday assume the role of Mister Miyagi. The idea is that as your worker's skills and proficiency improve over time, you build up a larger pool of Assessors.</p>
                        </div>


                        <div className="grid-item grid-item-flex">
                            <aside>
                                <ReactWOW animation="fadeInLeft" delay="0.3s"><img src='/features/competencies/driverassess.png' alt="Driver Assessment Screenshot" /></ReactWOW>
                            </aside>
                        </div>


                    </div>
                </div>
            </section>

            {/* FEATURE OVERVIEW */}

            <section className="feature-overview-container">
                <h2>Overview</h2>

                <div className="feature-overview-grid" style={{ marginBottom: '60px' }}>
                    {featureIndex.map(item => (
                        <FeatureCards title={item.title} para={item.para} />
                    ))}
                </div>
            </section>


        </Layout>
    )
}